<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>Password Settings</h1>
      <div class="btns-container">
        <VasionButton
          class="last-btn"
          :classProp="'primary'"
          :isDisabled="!isDirty"
          @vasionButtonClicked="save()"
        >
          Save 
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <VasionDropList
        v-slot="slotItem"
        v-model="selectedPasswordsExpire"
        :dataArray="passwordsExpireOptions"
        class="vertical-space"
        title="Passwords Expire"
        type="plain-list"
        width="392"
        valueName="value"
        displayName="name"
        @input="isDirty = true"
      >
        {{ slotItem.item.name }}
      </VasionDropList>
      <div class="collapse-section-header">
        <VasionButton
          name="collapse-section-button"
          class="collapse-section-button"
          :icon="showPasswordSettingSection ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
          title="Collapse Section"
          @vasionButtonClicked="showPasswordSettingSection = !showPasswordSettingSection"
        />
        <label class="vasion-page-subheader">Password Settings</label>
      </div>
      <div v-show="showPasswordSettingSection" class="section"> 
        <VasionCheckbox
          id="require-upper-and-lower-case-characters"
          name="require-upper-and-lower-case-characters"
          class="checkbox-vertical-space"
          :checked="requireUpperAndLowerCaseCharacters"
          @change="requireUpperAndLowerCaseCharacters = !requireUpperAndLowerCaseCharacters; isDirty = true"
        >
          Require Upper and Lower Case Characters
        </VasionCheckbox>

        <VasionCheckbox
          id="require-at-least-one-number"
          name="require-at-least-one-number"
          class="checkbox-vertical-space"
          :checked="requireAtLeastOneNumber"
          @change="requireAtLeastOneNumber = !requireAtLeastOneNumber; isDirty = true" 
        >
          Require at Least One Number
        </VasionCheckbox>

        <VasionCheckbox
          id="require-at-least-one-non-alphanumeric-character"
          name="require-at-least-one-non-alphanumeric-character"
          class="checkbox-vertical-space"
          :checked="requireAtLeastOneNonAlphanumericCharacter"
          @change="requireAtLeastOneNonAlphanumericCharacter = !requireAtLeastOneNonAlphanumericCharacter; isDirty = true"
        >
          Require at Least One Non-AlphanumericCharacter
        </VasionCheckbox>

        <VasionInput
          v-model="selectedMinimumNumberCharacters"
          inputType="top-white"
          class="checkbox-vertical-space"
          width="392"
          title="MINIMUM NUMBER OF CHARACTERS"
          specialType="number"
          @input="isDirty = true"
        />

        <VasionInput
          v-model="selectedMaximumNumberCharacters"
          inputType="top-white"
          class="vertical-space"
          width="392"
          title="MAXIMUM NUMBER OF CHARACTERS"
          specialType="number"
          @input="isDirty = true"
        />

        <VasionDropList
          v-slot="slotItem"
          v-model="selectedReusePreviousPassword"
          class="vertical-space"
          :dataArray="reusePasswordOptions"
          title="Can Reuse Previous Password"
          type="plain-list"
          width="392"
          valueName="value"
          displayName="name"
          @input="isDirty = true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>
    </div>
    <md-dialog
      id="confirmLeave"
      :md-active.sync="showConfirmLeave"
      @md-clicked-outside="showConfirmLeave = false"
    >
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="showConfirmLeave = false"
        @yesButtonClick="leaveWithoutSaving()"
      />
    </md-dialog>
    <VasionSnackbar
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
export default {
  name: 'PasswordSettings',
  beforeRouteLeave(to, _, next) { 
    if (this.isDirty) {
      this.routeTo = to
      this.showConfirmLeave = true
      return
    } 
    next()
  },
  data: function () {
    return {
      isDirty: false,
      passwordsExpireOptions: [
        {name: 'Never', value: 0},
        { name: '1 Week', value: 7},
        { name: '1 Month', value: 30 },
        { name: '3 Months', value: 90 },
        { name: '6 Months', value: 180 },
        { name: '1 Year', value: 365 }
      ],
      reusePasswordOptions: [
        { name: 'Never', value: 0 },
        {name: 'After 1 Password', value: 1},
        { name: 'After 5 Passwords', value: 5},
        { name: 'After 10 Passwords', value: 10 },
        { name: 'After 25 Passwords', value: 25 },
        { name: 'After 50 Passwords', value: 50 },
      ],
      requireAtLeastOneNumber: false,
      requireUpperAndLowerCaseCharacters: false,
      requireAtLeastOneNonAlphanumericCharacter: false,
      routeTo: null,
      selectedMinimumNumberCharacters: null,
      selectedMaximumNumberCharacters: null,
      selectedPasswordsExpire: {},
      selectedReusePreviousPassword: {},
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      showConfirmLeave: false,
      showPasswordSettingSection: false,
    }
  },
  async created() {
    const passwordSettings = await this.$store.dispatch('common/getPasswordSchema')
    this.loadSavedData(passwordSettings)
  },
  methods: {
    leaveWithoutSaving() {
      this.isDirty = false
      this.$router.push({ path: this.routeTo.path })
    },
    loadSavedData(passwordSettings) {
      this.selectedPasswordsExpire = this.passwordsExpireOptions.find(
        option => option.value === passwordSettings.passwordsExpire
      )

      this.requireUpperAndLowerCaseCharacters = passwordSettings.upperAndLowerCase
      this.requireAtLeastOneNumber = passwordSettings.atLeastOneNumber
      this.requireAtLeastOneNonAlphanumericCharacter = passwordSettings.atLeastOneNonAlphaNumeric

      this.selectedMinimumNumberCharacters = passwordSettings.minChars
      this.selectedMaximumNumberCharacters = passwordSettings.maxChars

      this.selectedReusePreviousPassword = this.reusePasswordOptions.find(
        option => option.value === passwordSettings.reusePrevious
      )
    },
    async save () {
      const payload = {
        passwordsExpire: this.selectedPasswordsExpire.value,
        upperAndLowerCase: this.requireUpperAndLowerCaseCharacters,
        atLeastOneNumber: this.requireAtLeastOneNumber,
        atLeastOneNonAlphaNumeric: this.requireAtLeastOneNonAlphanumericCharacter,
        maxChars: Number(this.selectedMaximumNumberCharacters),
        minChars: Number(this.selectedMinimumNumberCharacters),
        reusePrevious: this.selectedReusePreviousPassword.value,
      }
      const response = await this.$store.dispatch('admin/savePasswordSettings', payload)
      if (response) {
        this.isDirty = false
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Password Settings Saved.'
        this.snackbarImage = true
        this.showSnackbarBool = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Password Settings not saved.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .checkbox-vertical-space {
    margin-top: 27px;
  }

  .vertical-space {
    margin-top: 16px;
  }

  .collapse-section-button {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    background-color: $grey-75;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .collapse-section-button:hover {
    border-radius: 8px;
  }

  .collapse-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 3px;
  }
</style>
